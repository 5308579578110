<template>
    <GlobalWindow
      :title="title"
      width="50%"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="产品名称" prop="productName">
          <el-input v-model="form.productName" placeholder="请输入产品名称" v-trim/>
        </el-form-item>
        <el-form-item label="产品分类" prop="type">
            <el-select v-model="form.type" placeholder="请选择产品分类">
                <el-option
                    v-for="item in cateList()"
                    :key="item.id"
                    :label="item.productTypeName"
                    :value="item.id">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="产品价格" prop="price">
          <el-input v-model="form.price" type="number" placeholder="请输入产品价格" v-trim/>
        </el-form-item>
        <el-form-item label="产品主图" prop="homepagePic">
            <el-upload
            :action="action"
            list-type="picture-card"
            :limit="1"
            :on-exceed="exceed"
            :file-list="fileList"
            :on-success="fileSuccess"
            :on-remove="handleRemove">
                <i class="el-icon-plus"></i>
            </el-upload>
        </el-form-item>
        <el-form-item label="产品轮播图" prop="files">
            <el-upload
            :action="action1"
            :multiple="false"
            list-type="picture-card"
            :file-list="fileList1"
            :on-success="fileSuccess1"
            :on-remove="handleRemove1">
                <i class="el-icon-plus"></i>
            </el-upload>
        </el-form-item>
        <el-form-item label="产品详情" prop="describe">
            <el-tiptap
            v-model="form.describes"
            :extensions="extensions"
            />
        </el-form-item>
      </el-form>
    </GlobalWindow>
  </template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import {
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Image,
  Blockquote,
  TextAlign,
  Indent,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  TextColor,
  HorizontalRule
} from 'element-tiptap'
import { upload } from '@/api/BUS/product'
export default {
  name: 'OperaProductWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        productName: '',
        homepagePic: '',
        type: '',
        price: '',
        files: [],
        describes: ''
      },
      fileList: [],
      fileList1: [],
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true, menubar: false }),
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new Image({
          uploadRequest (file) {
            const fd = new FormData()
            fd.append('file', file)
            return upload(fd).then(res => {
              return res.realFilePath
            })
          }
        }),
        new Blockquote(),
        new TextAlign(),
        new Indent(),
        new Table(),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new HorizontalRule(),
        new TextColor()
      ],
      action: process.env.VUE_APP_API_PREFIX + '/bus/attachment/upload',
      action1: process.env.VUE_APP_API_PREFIX + '/bus/attachment/uploadBath',
      // 验证规则
      rules: {
        productName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' }
        ],
        // homepagePic: [
        //     { required: true, message: '请上传产品页图', trigger: 'blur' }
        // ],
        type: [
          { required: true, message: '请选择产品分类', trigger: 'change' }
        ],
        price: [
          { required: true, message: '请输入产品价格', trigger: 'blur' }
        ]
        // files: [
        //     { required: true, message: '请上传产品轮播图', trigger: 'blur' }
        // ]
      }
    }
  },
  inject: ['cateList'],
  created () {
    this.config({
      api: '/BUS/product',
      'field.id': 'id'
    })
  },
  methods: {
    /**
         * 打开窗口
         *
         * @param title 窗口标题
         * @param target 行对象（仅编辑需该参数）
         */
    open (title, target) {
      this.title = title
      this.visible = true
      this.fileList = []
      this.fileList1 = []
      this.form.files = []
      this.form.describes = ''

      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form.id = null
          this.form.productName = target.productName
          this.form.homepagePic = target.homepagePic
          this.form.type = target.type
          this.form.price = target.price
          this.form.describes = target.describes
          if (target.homepagePic) {
            this.fileList.push({ name: target.homepagePic, url: target.homepagePicPath })
          }
          if (target.filePaths.length > 0) {
            target.filePaths.forEach(item => {
              this.fileList1.push({ name: item.id, url: item.filePath })
              this.form.files.push(item.id)
            })
          }
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        this.form.id = target.id
        this.form.productName = target.productName
        this.form.homepagePic = target.homepagePic
        this.form.type = target.type
        this.form.price = target.price
        this.form.describes = target.describes
        if (target.homepagePic) {
          this.fileList.push({ name: target.homepagePic, url: target.homepagePicPath })
        }
        if (target.filePaths.length > 0) {
          target.filePaths.forEach(item => {
            this.fileList1.push({ name: item.id, url: item.filePath })
            this.form.files.push(item.id)
          })
        }
      })
    },
    exceed () {
      this.$tip.warning('只能传一张主图')
    },
    fileSuccess (res) {
      this.form.homepagePic = res.data.fileName
      this.fileList.push({ name: res.data.fileName, url: res.data.filePath })
    },
    handleRemove (file, fileList) {
      this.form.homepagePic = ''
      this.fileList.forEach((element, index) => {
        if (element.id === file.id) {
          this.fileList.splice(index, 1)
        }
      })
    },
    fileSuccess1 (res) {
      this.form.files.push(res.data.id)
      this.fileList1.push({ id: res.data.id, name: res.data.fileName, url: res.data.filePath })
    },
    handleRemove1 (file, fileList) {
      this.form.files.forEach((element, index) => {
        if (element === file.name) {
          this.form.files.splice(index, 1)
        }
      })
      this.fileList1.forEach((element, index) => {
        if (element.id === file.name) {
          this.fileList1.splice(index, 1)
        }
      })
    }
  }
}
</script>
