<template>
    <GlobalWindow
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="商品类型名称" prop="productTypeName">
          <el-input v-model="form.productTypeName" placeholder="请输入商品类型名称" v-trim/>
        </el-form-item>
      </el-form>
    </GlobalWindow>
  </template>
  
  <script>
  import BaseOpera from '@/components/base/BaseOpera'
  import GlobalWindow from '@/components/common/GlobalWindow'
  export default {
    name: 'OperaProductTypeWindow',
    extends: BaseOpera,
    components: { GlobalWindow },
    data () {
      return {
        // 表单数据
        form: {
          id: null,
          productTypeName: ''
        },
        // 验证规则
        rules: {
        }
      }
    },
    created () {
      this.config({
        api: '/BUS/productType',
        'field.id': 'id'
      })
    }
  }
  </script>
  