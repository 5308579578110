<template>
    <TableLayout :permissions="['bus:product:query']">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
        <el-form-item label="产品名称" prop="productName">
          <el-input v-model="searchForm.productName" placeholder="请输入产品名称" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="产品分类" prop="type">
            <el-select v-model="searchForm.type" placeholder="请选择产品分类" @change="search">
                <el-option
                v-for="item in cateList"
                :key="item.id"
                :label="item.productTypeName"
                :value="item.id">
                </el-option>
            </el-select>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
      <!-- 表格和分页 -->
      <template v-slot:table-wrap>
        <ul class="toolbar" v-permissions="['bus:product:create', 'bus:product:delete']">
          <li><el-button type="primary" @click="$refs.operaProductWindow.open('新建产品')" icon="el-icon-plus" v-permissions="['bus:product:create']">新建</el-button></li>
          <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['bus:product:delete']">删除</el-button></li>
        </ul>
        <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="productName" label="产品名称" min-width="100px"></el-table-column>
          <el-table-column label="产品主图" min-width="100px">
            <template slot-scope="{row}">
                <el-image
                style="width: 100px; height: 100px"
                :src="row.homepagePicPath"
                fit="cover"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="productTypeName" label="产品分类" min-width="100px"></el-table-column>
          <el-table-column prop="createUserName" label="创建人" min-width="100px"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" min-width="100px"></el-table-column>
          <!-- <el-table-column prop="updateUser" label="更新人" min-width="100px"></el-table-column>
          <el-table-column prop="updateTime" label="更新时间" min-width="100px"></el-table-column> -->
          <el-table-column
            v-if="containPermissions(['bus:product:update', 'bus:product:delete'])"
            label="操作"
            min-width="120"
            fixed="right"
          >
            <template slot-scope="{row}">
              <el-button type="text" @click="$refs.operaProductWindow.open('编辑产品', row)" icon="el-icon-edit" v-permissions="['bus:product:update']">编辑</el-button>
              <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['bus:product:delete']">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        >
        </pagination>
      </template>
      <!-- 新建/修改 -->
      <OperaProductWindow ref="operaProductWindow" @success="handlePageChange"/>
    </TableLayout>
  </template>
  
  <script>
  import BaseTable from '@/components/base/BaseTable'
  import TableLayout from '@/layouts/TableLayout'
  import Pagination from '@/components/common/Pagination'
  import OperaProductWindow from '@/components/BUS/OperaProductWindow'
  import { findAll } from '@/api/BUS/productType'
  export default {
    name: 'Product',
    extends: BaseTable,
    components: { TableLayout, Pagination, OperaProductWindow },
    data () {
      return {
        // 搜索
        searchForm: {
          productName: '',
          type: ''
        },
        cateList: []
      }
    },
    created () {
      this.config({
        module: '',
        api: '/BUS/product',
        'field.id': 'id',
        'field.main': 'id'
      })
      this.search()
      this.getAllCate()
    },
    provide() {
        return {
            cateList: () => this.cateList
        }
    },
    methods: {
        getAllCate() {
            findAll({})
                .then(res => {
                    this.cateList = res
                })
        }
    }
  }
  </script>
  