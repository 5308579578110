import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/bus/product/pageNew', data, {
    trim: true
  })
}

// 创建
export function create (data) {
  return request.post('/bus/product/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/bus/product/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/bus/product/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/bus/product/delete/batch', {
    params: {
      ids
    }
  })
}

// 文件上传 -- 产品主图
export function upload (data) {
    return request.post('/bus/attachment/upload', data)
}

// 文件上传 -- 产品轮播
export function uploadBath (data) {
    return request.post('/bus/attachment/uploadBath', data)
}