import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/bus/productType/page', data, {
    trim: true
  })
}

// 创建
export function create (data) {
  return request.post('/bus/productType/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/bus/productType/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/bus/productType/delete/${id}`)
}

// 获取所有的产品类型
export function findAll () {
  return request.get(`/bus/productType/findAll`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/bus/productType/delete/batch', {
    params: {
      ids
    }
  })
}
